import { kebabCase } from 'element-ui/src/utils/util'
/**
 * Show migrating guide in browser console.
 *
 * Usage:
 * import Migrating from 'element-ui/src/mixins/migrating'
 *
 * mixins: [Migrating]
 *
 * add getMigratingConfig method for your component.
 *  getMigratingConfig() {
 *    return {
 *      props: {
 *        'allow-no-selection': 'allow-no-selection is removed.',
 *        'selection-mode': 'selection-mode is removed.'
 *      },
 *      events: {
 *        selectionchange: 'selectionchange is renamed to selection-change.'
 *      }
 *    }
 *  },
 */
export default {
    mounted() {
        if (process.env.NODE_ENV === 'production') { return }
        if (!this.$vnode) { return }
        const { props = {}, events = {} } = this.getMigratingConfig()
        const { data, componentOptions } = this.$vnode
        const definedProps = data.attrs || {}
        const definedEvents = componentOptions.listeners || {}

        for (let propName in definedProps) {
            propName = kebabCase(propName) // compatible with camel case
            if (props[propName]) {
                console.warn(`[Element Migrating][${this.$options.name}][Attribute]: ${props[propName]}`)
            }
        }

        for (let eventName in definedEvents) {
            eventName = kebabCase(eventName) // compatible with camel case
            if (events[eventName]) {
                console.warn(`[Element Migrating][${this.$options.name}][Event]: ${events[eventName]}`)
            }
        }
    },
    methods: {
        getMigratingConfig() {
            return {
                props: {},
                events: {}
            }
        }
    }
}
