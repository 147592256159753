import { getStyle } from './util'
export default {
	name: 'empty',
	bind: function(el, binding) {
		if (binding.value) {
			showEmpty(el, binding.value);
		}
	},
	update: function(el, binding) {
		if (binding.value) {
			showEmpty(el, binding.value)
		} else {
			hideEmpty(el)
		}
	}
}

function showEmpty(el,value) {
	var empty = el.getElementsByClassName('empty_dome')[0]
	if (empty) {
		empty.style.display = 'flex';
	} else {
		cratedEmpty(el)
		empty = el.getElementsByClassName('empty_dome')[0]
		empty.style.display = 'flex';
	}
	if(typeof value == 'string'){
		empty.getElementsByTagName('span')[0].innerText = value
	}
}

function hideEmpty(el) {
	var empty = el.getElementsByClassName('empty_dome')[0]
	if (empty) {
		empty.style.display = 'none';
	}
}


const positionValue = ['relative','absolute','fixed','sticky']
function cratedEmpty(el) {
	let style = el.style;
	if (!positionValue.includes(getStyle(el, 'position'))) {
		style.position = 'relative'
	}
	let dome = document.createElement('div');
	dome.className = 'empty_dome';
	dome.style.position = 'absolute';
	dome.style.left = '0px';
	dome.style.right = '0px';
	dome.style.top = '0px';
	dome.style.bottom = '0px';
	dome.style.background = '#f9f9f9';
	dome.style.display = 'flex';
	dome.style.flexDirection = 'column';
	dome.style.justifyContent = 'center';
	dome.style.textAlign = 'center';
	dome.style.display = 'none';

	let textSpan = document.createElement('span');
	textSpan.innerText = '暂无数据';
	textSpan.style.color='#979797';
	textSpan.style.fontSize = '16px';
	textSpan.style.fontWeight = 'normal';
	textSpan.style.letterSpacing = '1px';
	dome.appendChild(textSpan);

	el.empty = dome;
	el.appendChild(dome)
}
