import { render, staticRenderFns } from "./i-cont-ui.vue?vue&type=template&id=0ecd8d39&scoped=true"
import script from "./i-cont-ui.vue?vue&type=script&lang=js"
export * from "./i-cont-ui.vue?vue&type=script&lang=js"
import style0 from "./i-cont-ui.vue?vue&type=style&index=0&id=0ecd8d39&prod&scoped=jy-i_cont&lang=scss"
import style1 from "./i-cont-ui.vue?vue&type=style&index=1&id=0ecd8d39&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ecd8d39",
  null
  
)

export default component.exports