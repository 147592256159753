<template>
	<div class="jy-i_cont">
		<div class="i_cont_flex">
			<div class="left">
				<i :class="icon"></i>
			</div>
			<div class="right">
				<slot>
					<div class="title">{{title}}</div>
					<div class="dis">{{dis}}</div>
					<el-upload :limit="1" ref="upload" :action="action" :auto-upload="false" v-if="comType=='upload'" :on-change="onChange" :on-success="onSuccess" :on-exceed="onExceed" :before-upload="beforeUpload" v-bind:key="comKey" :headers="headers">
						<span class="button_span" slot="trigger" v-on:click="buttonFun">{{butText}}</span>
					</el-upload>
					<span class="button_span" slot="trigger" v-on:click="buttonFun" v-else>{{butText}}</span>
				</slot>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
	props: {
		icon: {
			type: String,
			default: 'el-icon-upload'
		},
		title: {
			type: String,
			default: 'title'
		},
		dis: {
			type: String,
			default: 'dis'
		},
		butText: {
			type: String,
			default: 'butText'
		},
		comType: {
			type: String,
		},
		action:{
			type:String,
			default:''
		},
		headers:{
			type:Object,
		},
		fileSize:{
			type:Number,//单位为m
		},
		fileType:{
			type:[String,Array],
		}
	},
	data() {
		return {
			uploadSuccess: null,
			uploadData:null,
			comKey:new Date().getTime(),
		}
	},
	wacth:{
		uploadData:function(val){
			console.log(val)
		}
	},
	computed:{
		fileSizeNumber:function(){
			let size = this.fileSize;
			//1024b==1kb
			//1204kb==1m
			return size*1024*1024
		},
	},
	methods: {
		buttonFun() {
			this.$emit('butFun')
		},
		onChange(file) {
			console.log('file',file)
		},
		uploadFun(callBack) {
			let readyFileList = this.$refs.upload.uploadFiles.filter(file=> file.status=='ready')
			if(readyFileList.length==0){
				callBack && callBack(this.uploadData)
			}else{
				this.$refs.upload.submit();
				this.uploadSuccess = callBack;
			}
		},
		onSuccess(data) {
			if(data.code==0){
				this.uploadData = JSON.parse(JSON.stringify(data));
				console.log(this)
				this.uploadSuccess && this.uploadSuccess(JSON.parse(JSON.stringify(this.uploadData)));
			}else{
				this.$message({
					type:'error',
					message:data.msg||data.message||data.error
				})
			}
		},
		onExceed(){
			this.$message({
				type:'warning',
				message:'只能上传一个文件，请先删除再上传'
			})
		},
		beforeUpload(file){
			if(this.fileType){
				console.log(file)
				let fileType = file.name.split('.')
				fileType = fileType[fileType.length-1]
				if(typeof this.fileType === 'string'){
					if(fileType != this.fileType){
                        this.warningFun('fileType',fileType)
                        this.$emit('failUpload')
						return false
					}
				}else{
					if(!this.fileType.includes(fileType)){
                        this.warningFun('fileType',fileType)
                        this.$emit('failUpload')
						return false
					}
				}
			}
			if(this.fileSize && this.fileSizeNumber < file.size){
                this.warningFun('size')
                this.$emit('failUpload')
				return false
			}
			return true
		},
		warningFun(type,fileType){
			if(type=='size'){
				this.$message({
					type:'warning',
					message:'上传文件不得大于 '+this.fileSize + 'Mb'
				})
			}else if(type=='fileType'){
				this.$message({
					type:'warning',
					message:'不支持后缀为 '+fileType + ' 的文件'
				})
			}
		},
		updateCom(){
			this.comKey=new Date().getTime();
		},
	}
}

</script>
<style scoped="jy-i_cont" lang="scss">
.jy-i_cont {
	.i_cont_flex {
		display: flex;
		align-items: center;
		border: 1px solid #dcdcdc;

		.left {
			width: 80px;
			text-align: center;
			background: #dedede;
			height: 80px;
			line-height: 80px;

			i {
				font-size: 30px;
				position: relative;
				top: 2px;
			}
		}

		.right {
			padding-left: 20px;

			.title {
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 6px;
			}

			.dis {
				margin-bottom: 6px;
				font-size: 14px;
			}

			.button_span {
				color: #4077bc;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}
}

</style>
<style type="text/css" lang="scss">
.jy-i_cont {
	.el-upload {
		vertical-align: middle;
	}

	.el-upload-list {
		display: inline-block;
		width: 300px;
		vertical-align: middle;

		.el-upload-list__item:first-child {
			margin-top: 0;
		}
	}
}

</style>
