<template>
	<div class="jy_admin" v-bind:style="heightStyle">
		<div class="left" v-if="showLeft" v-bind:style="leftStyle">
			<slot name="left">
				<jy-menu :menu="menu"></jy-menu>
			</slot>
			<div class="arrow_box" v-if="canHide" v-on:click="setIsHide()">
				<div class="i_box" :class="{'to_left':isHide}">
					<i class="el-icon-arrow-left"></i>
				</div>
			</div>
		</div>

		<div v-if="showLeft" class="right" :class="{'scroll':showLeft}" v-bind:style="rightStyle">
			<slot></slot>
		</div>
		<div v-else class="right" :class="{'scroll':true}" v-bind:style="rightStyle">
			<slot></slot>
		</div>
	</div>
</template>
<script>
import jyMenu from './menu';
export default {
	name: 'jy-container',
	props: {
		showLeft: {
			type: Boolean,
			default: true,
		},
		menu: {
			type: Array,
			default: function() {
				return [];
			}
		},
		leftWidth: {
			type: [String, Number],
			default: 220
		},
		canHide: {
			type: Boolean,
			default: false,
		},
		rightStyle:{
			type:[Object,String]
		},
	},
	data() {
		return {
			heightStyle: {},
			leftStyle: {},
			isHide: false,
		}
	},
	components: {
		jyMenu,
	},
	watch: {
		leftWidth: function() {
			this.setLeftWidth();
		},
		isHide: function(val) {
			if (val) {
				this.setLeftWidth(0);
			} else {
				this.setLeftWidth();
			}
		},
	},
	created() {
		window.onresize = () => {
			this.setHeight();
		}
		this.setHeight();
		this.setLeftWidth();
	},
	methods: {
		setLeftWidth(w) {
			let obj = {};
			let lw = w === undefined ? this.leftWidth : w;
			if (this.canHide) {
				obj.padding = '0 20px 0 0';
				lw = Number(lw) + 20
			}
			if (typeof lw == 'string') {
				if (/%&/.test(lw)) {
					obj.width = lw;
				} else {
					obj.width = Number(lw) + 'px';
				}
			} else {
				obj.width = lw + 'px';
			}
			this.leftStyle = obj;

		},
		setHeight() {
			let obj = {};
			// if (this.showLeft) {
				let h = window.innerHeight - 60
				obj = {
					...this.heightStyle,
					height: h + 'px'
				}
				window.$('html,body').css({
					height: '100%',
					overflow: 'hidden'
				});
			// }
			this.heightStyle = obj
		},
		setIsHide(b) {
			if (b !== undefined) {
				this.isHide = b;
			} else {
				this.isHide = !this.isHide;
			}
		},
	}
}

</script>
<style lang="scss" scoped="jy_admin">
@import '../config/variable.scss';
$anima_time:0.5s;

.jy_admin {
	display: flex;
	width: 100%;

	.left {
		width: 220px;
		height: 100%;
		overflow: hidden;
		box-shadow: 0 0 6px 1px #555;
		position: relative;
		transition: width $anima_time;

		.arrow_box {
			width: 20px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			border-left: 1px solid $bordercolor;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: $menu_bg;
			@extend .hand;

			.i_box {
				transition: transform $anima_time;

				&.to_left {
					transform: rotate(180deg);
				}
			}
		}
	}

	.right {
		flex: 1;
		padding: 20px;

		&.scroll {
			height: 100%;
			overflow-y: scroll;
		}
	}
}

</style>
