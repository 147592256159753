import Vue from "vue";
import VueRouter from "vue-router";
import Util from "@/common/js/util";
import store from "./store";
import { MessageBox } from "element-ui";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "default",
        redirect: "admin/home"
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/login.vue")
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("./views/admin/entry.vue"),
        redirect: "/admin/home",
        children: [
            {
                path: "home",
                name: "home",
                component: () => import("./views/admin/home1/home1.vue")
            },
            {
                path: "realTime",
                name: "realTime",
                component: () => import("./views/admin/realTime/index.vue")
            },
            {
                path: "videoMonitor",
                name: "videoMonitor",
                component: () => import("./views/admin/videoMonitor/entry.vue"),
                children: [
                    {
                        path: "realTime",
                        name: "real",
                        component: () => import("./views/admin/videoMonitor/real/index.vue")
                    },
                    {
                        path: "history",
                        name: "history",
                        component: () => import("./views/admin/videoMonitor/history/index.vue")
                    }
                ]
            },
            {
                path: "serviceMap",
                name: "serviceMap",
                component: () => import("./views/admin/serviceMap/index.vue")
            },
            {
                path: "electricMap",
                name: "monitorMap",
                component: () => import("./views/admin/monitorMap/index.vue")
            },
            {
                path: "track",
                name: "track",
                component: () => import("./views/admin/track/index.vue")
            },
            {
                path: "siteTrack",
                name: "siteTrack",
                component: () => import("./views/admin/siteTrack/index.vue")
            },
            {
                path: "/areaCar/areaSeachCar",
                name: "areaCar/areaSeachCar",
                component: () => import("./views/admin/areaCar/areaSeachCar.vue")
            },
            {
                path: "shiftRealTimeInfor",
                name: "shiftRealTimeInfor",
                component: () => import("./views/admin/shiftRealTimeInfor/index.vue")
            },
            {
                path: "operationalInformation",
                name: "operationalInformationDefault",
                component: () => import("./views/admin/operationalInformation/entry.vue")
            },
            {
                path: "operationalInformation/:type",
                name: "operationalInformation",
                component: () => import("./views/admin/operationalInformation/entry.vue")
            },
            {
                path: "scheduling",
                name: "scheduling",
                component: () => import("./views/admin/scheduling/entry.vue"),
                redirect: "/admin/scheduling/dailyTimetable",
                children: [
                    {
                        path: "dailyTimetable",
                        name: "dailyTimetable",
                        component: () => import("./views/admin/scheduling/dailyTimetable.vue")
                    },
                    {
                        path: "plannedShift",
                        name: "plannedShift",
                        component: () => import("./views/admin/scheduling/plannedShift.vue")
                    },
                    {
                        path: "rotationPlan",
                        name: "rotationPlan",
                        component: () => import("./views/admin/scheduling/rotationPlan.vue")
                    },
                    {
                        path: "shiftList",
                        name: "shiftList",
                        component: () => import("./views/admin/scheduling/shiftList.vue")
                    }
                ]
            },
            {
                path: "timer",
                name: "timer",
                component: () => import("./views/admin/timer/entry.vue"),
                redirect: "/admin/scheduling/dailyTimetable",
                children: [
                    {
                        path: "vehicleRoute",
                        name: "vehicleRoute",
                        component: () => import("./views/admin/timer/vehicleRouter.vue")
                    },
                    {
                        path: "plannedShift",
                        name: "plannedShift",
                        component: () => import("./views/admin/scheduling/plannedShift.vue")
                    }
                ]
            },
            {
                path: "roadBillInfo",
                name: "roadBillInfo",
                component: () => import("./views/admin/roadBillInfo/entry.vue"),
                children: [
                    {
                        path: "management",
                        name: "roadBillInfoManagement",
                        component: () => import("./views/admin/roadBillInfo/management.vue"),
                    },
                    {
                        path: "dreport",
                        name: "roadBillInfoDreport",
                        component: () => import("./views/admin/roadBillInfo/dreport.vue"),
                    },
                    {
                        path: "audit",
                        name: "roadBillInfoAudit",
                        component: () => import("./views/admin/roadBillInfo/audit.vue")
                    },
                    {
                        path: "statistics",
                        name: "roadBillInfoStatistics",
                        component: () => import("./views/admin/roadBillInfo/statistics.vue")
                    }
                ]
            },
            {
                path: "timing",
                name: "timing",
                component: () => import("./views/admin/timing/entry.vue"),
                children: [
                    {
                        path: "carRoterChange",
                        name: "carRoterChange",
                        component: () => import("./views/admin/timing/carRoterChange.vue")
                    }
                ]
            },
            {
                path: "resourcesAllocation",
                name: "resourcesAllocation",
                component: () => import("./views/admin/resourcesAllocation/entry.vue"),
                redirect: "/admin/resourcesAllocation/lineSite",
                children: [
                    {
                        path: "lineSite",
                        name: "lineSite",
                        component: () => import("./views/admin/resourcesAllocation/lineSite.vue")
                    },
                    {
                        path: "lineCar",
                        name: "lineCar",
                        component: () => import("./views/admin/resourcesAllocation/lineCar.vue")
                    },
                    {
                        path: "depotCar",
                        name: "depotCar",
                        component: () => import("./views/admin/resourcesAllocation/depotCar.vue")
                    }
                ]
            },
            {
                path: "waybillManagement",
                name: "waybillManagement",
                component: () => import("./views/admin/waybillManagement/entry.vue"),
                redirect: "/admin/waybillManagement/collection",
                children: [
                    {
                        path: "collection",
                        name: "collectionDefault",
                        component: () => import("./views/admin/waybillManagement/collection/entry.vue")
                        // redirect: '/admin/waybillManagement/collection/waybillCollection',
                    },
                    {
                        path: "collection/:type",
                        name: "collection",
                        component: () => import("./views/admin/waybillManagement/collection/entry.vue")
                    },
                    {
                        path: "waybillReview",
                        name: "waybillReviewDefault",
                        component: () => import("./views/admin/waybillManagement/waybillReview/entry.vue")
                        // redirect: '/admin/waybillManagement/waybillReview/waybillCollectionReview',
                    },
                    {
                        path: "waybillReview/:type",
                        name: "waybillReview",
                        component: () => import("./views/admin/waybillManagement/waybillReview/entry.vue")
                    }
                ]
            },
            {
                path: "setting",
                name: "setting",
                component: () => import("./views/admin/setting/entry.vue"),
                redirect: "/admin/setting/strandedAcrossSetting",
                children: [
                    {
                        path: "strandedAcrossSetting",
                        name: "strandedAcrossSetting",
                        component: () => import("./views/admin/setting/strandedAcrossSetting.vue")
                    },
                    {
                        path: "sparseDenseSetting",
                        name: "sparseDenseSetting",
                        component: () => import("./views/admin/setting/sparseDenseSetting.vue")
                    },
                    {
                        path: "onTimeSetting",
                        name: "onTimeSetting",
                        component: () => import("./views/admin/setting/onTimeSetting.vue")
                    },
                    {
                        path: "intervalSetting",
                        name: "intervalSetting",
                        component: () => import("./views/admin/setting/intervalSetting.vue")
                    },
                    {
                        path: "timeLengthSetting",
                        name: "timeLengthSetting",
                        component: () => import("./views/admin/setting/timeLengthSetting.vue")
                    }
                ]
            },
            {
                path: "passengerTemperature",
                name: "passengerTemperature",
                component: () => import("./views/admin/passengerTemperature/entry.vue"),
                redirect: "/admin/passengerTemperature/show",
                children: [
                    {
                        path: "show",
                        name: "show",
                        component: () => import("./views/admin/passengerTemperature/show.vue")
                    }
                ]
            },
            {
                path: "statisticalAnalysis/passengerFlow",
                name: "passengerFlow",
                component: () => import("./views/admin/statisticalAnalysis/passengerFlow/entry.vue"),
                redirect: "/admin/statisticalAnalysis/passengerFlow/passengerFlowDetail",
                children: [
                    {
                        path: "passengerFlowDetail",
                        name: "passengerFlowDetail",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/passengerFlowDetail.vue")
                    },
                    {
                        path: "passengerFlowReport",
                        name: "passengerFlowReport",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/passengerFlowReport.vue")
                    },
                    {
                        path: "dailyPassengerFlow",
                        name: "dailyPassengerFlow",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/dailyPassengerFlow.vue")
                    },
                    {
                        path: "monthPassengerFlow",
                        name: "monthPassengerFlow",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/monthPassengerFlow.vue")
                    },
                    {
                        path: "wayPassengerFlow",
                        name: "wayPassengerFlow",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/wayPassengerFlow.vue")
                    },
                    {
                        path: "demandPassengerFlow",
                        name: "demandPassengerFlow",
                        component: () => import("./views/admin/statisticalAnalysis/passengerFlow/demandPassengerFlow.vue")
                    }
                ]
            },
            {
                path: "statisticalAnalysis",
                name: "statisticalAnalysis",
                component: () => import("./views/admin/statisticalAnalysis/entry.vue"),
                redirect: "/admin/statisticalAnalysis/passengerFlow",
                children: [
                    {
                        path: "business",
                        name: "business",
                        component: () => import("./views/admin/statisticalAnalysis/business/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/business/strandedAcrossAnalysis",
                        children: [
                            {
                                path: "strandedAcrossAnalysis",
                                name: "strandedAcrossAnalysis",
                                component: () => import("./views/admin/statisticalAnalysis/business/strandedAcrossAnalysis.vue")
                            },
                            {
                                path: "sparseDenseAnalysis",
                                name: "sparseDenseAnalysis",
                                component: () => import("./views/admin/statisticalAnalysis/business/sparseDenseAnalysis.vue")
                            },
                            {
                                path: "noOnTimeAnalysis",
                                name: "noOnTimeAnalysis",
                                component: () => import("./views/admin/statisticalAnalysis/business/noOnTimeAnalysis.vue")
                            },
                            {
                                path: "sintervalAnalysis",
                                name: "sintervalAnalysis",
                                component: () => import("./views/admin/statisticalAnalysis/business/sintervalAnalysis.vue")
                            },
                            {
                                path: "timeLengthAnalysis",
                                name: "timeLengthAnalysis",
                                component: () => import("./views/admin/statisticalAnalysis/business/timeLengthAnalysis.vue")
                            }
                        ]
                    },
                    {
                        path: "report",
                        name: "report",
                        component: () => import("./views/admin/statisticalAnalysis/report/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/report/reportCenter",
                        children: [
                            {
                                path: "reportCenter",
                                name: "reportCenter",
                                component: () => import("./views/admin/statisticalAnalysis/report/reportCenter.vue")
                            },
                            {
                                path: "parkingLot",
                                name: "parkingLot",
                                component: () => import("./views/admin/statisticalAnalysis/report/parkingLot.vue")
                            },
                            {
                                path: "mileage",
                                name: "mileage",
                                component: () => import("./views/admin/statisticalAnalysis/report/mileage.vue")
                            },
                            {
                                path: "VehicleOperation",
                                name: "VehicleOperation",
                                component: () => import("./views/admin/statisticalAnalysis/report/VehicleOperation.vue")
                            },
                            {
                                path: "speedingInformation",
                                name: "speedingInformation",
                                component: () => import("./views/admin/statisticalAnalysis/report/speedingInformation.vue")
                            },
                            {
                                path: "issuedInformation",
                                name: "issuedInformation",
                                component: () => import("./views/admin/statisticalAnalysis/report/issuedInformation.vue")
                            }
                        ]
                    },
                    {
                        path: "runProduction",
                        name: "runProduction",
                        component: () => import("./views/admin/statisticalAnalysis/runProduction/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/runProduction/runProductionCarTotal",
                        children: [
                            {
                                path: "runProductionCarTotal",
                                name: "runProductionCarTotal",
                                component: () => import("./views/admin/statisticalAnalysis/runProduction/runProductionCarTotal.vue")
                            },
                            {
                                path: "runProductionDriverTotal",
                                name: "runProductionDriverTotal",
                                component: () => import("./views/admin/statisticalAnalysis/runProduction/runProductionDriverTotal.vue")
                            }
                        ]
                    },
                    {
                        path: "workTime",
                        name: "workTime",
                        component: () => import("./views/admin/statisticalAnalysis/workTime/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/workTime/workTimeDriverTotal",
                        children: [
                            {
                                path: "workTimeDriverTotal",
                                name: "workTimeDriverTotal",
                                component: () => import("./views/admin/statisticalAnalysis/workTime/workTimeDriverTotal.vue")
                            },
                            {
                                path: "workTimeDriverDetail",
                                name: "workTimeDriverDetail",
                                component: () => import("./views/admin/statisticalAnalysis/workTime/workTimeDriverDetail.vue")
                            }
                        ]
                    },
                    {
                        path: "operatingMileage",
                        name: "operatingMileage",
                        component: () => import("./views/admin/statisticalAnalysis/operatingMileage/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/operatingMileage/operatingMileageCarTotal",
                        children: [
                            {
                                path: "operatingMileageCarTotal",
                                name: "operatingMileageCarTotal",
                                component: () => import("./views/admin/statisticalAnalysis/operatingMileage/operatingMileageCarTotal.vue")
                            },
                            {
                                path: "operatingMileageCarDetail",
                                name: "operatingMileageCarDetail",
                                component: () => import("./views/admin/statisticalAnalysis/operatingMileage/operatingMileageCarDetail.vue")
                            }
                        ]
                    },
                    {
                        path: "tripMileage",
                        name: "tripMileage",
                        component: () => import("./views/admin/statisticalAnalysis/tripMileage/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/tripMileage/tripMileageCarTotal",
                        children: [
                            {
                                path: "tripMileageCarTotal",
                                name: "tripMileageCarTotal",
                                component: () => import("./views/admin/statisticalAnalysis/tripMileage/tripMileageCarTotal.vue")
                            },
                            {
                                path: "tripMileageCarEveryDay",
                                name: "tripMileageCarEveryDay",
                                component: () => import("./views/admin/statisticalAnalysis/tripMileage/tripMileageCarEveryDay.vue")
                            }
                        ]
                    },
                    {
                        path: "GPStotal",
                        name: "GPStotal",
                        component: () => import("./views/admin/statisticalAnalysis/GPStotal.vue")
                    },
                    {
                        path: "RouteTotal",
                        name: "RouteTotal",
                        component: () => import("./views/admin/statisticalAnalysis/RouteTotal.vue")
                    },
                    {
                        path: "milShiftTotal",
                        name: "milShiftTotal",
                        component: () => import("./views/admin/statisticalAnalysis/milShiftTotal.vue")
                    },
                    {
                        path: "swipeStatistics",
                        name: "swipeStatistics",
                        component: () => import("./views/admin/statisticalAnalysis/swipeStatistics.vue")
                    },
                    {
                        path: "largeIntervalTotal",
                        name: "largeIntervalTotal",
                        component: () => import("./views/admin/statisticalAnalysis/largeIntervalTotal.vue")
                    },
                    {
                        path: "LinedeviationTotal",
                        name: "LinedeviationTotal",
                        component: () => import("./views/admin/statisticalAnalysis/LinedeviationTotal.vue")
                    },
                    {
                        path: "attendanceStatistics",
                        name: "attendanceStatistics",
                        component: () => import("./views/admin/statisticalAnalysis/attendanceStatistics.vue")
                    },
                    // {path: "attendance",
                    // name: "attendance",
                    // component: () => import("./views/admin/statisticalAnalysis/attendance/entry.vue"),
                    // redirect: "/admin/statisticalAnalysis/attendance/swipeStatistics",
                    // children: [
                    //     {
                    //         path: "swipeStatistics",
                    //         name: "swipeStatistics",
                    //         component: () => import("./views/admin/statisticalAnalysis/attendance/swipeStatistics.vue")
                    //     },
                    //     {
                    //         path: "attendanceStatistics",
                    //         name: "attendanceStatistics",
                    //         component: () => import("./views/admin/statisticalAnalysis/attendance/attendanceStatistics.vue")
                    //     }
                    // ]},
                    {
                        path: "workTime",
                        name: "workTime",
                        component: () => import("./views/admin/statisticalAnalysis/workTime/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/workTime/workTimeDriverTotal",
                        children: [
                            {
                                path: "workTimeDriverTotal",
                                name: "workTimeDriverTotal",
                                component: () => import("./views/admin/statisticalAnalysis/workTime/workTimeDriverTotal.vue")
                            },
                            {
                                path: "workTimeDriverDetail",
                                name: "workTimeDriverDetail",
                                component: () => import("./views/admin/statisticalAnalysis/workTime/workTimeDriverDetail.vue")
                            }
                        ]
                    },
                    {
                        path: "operatingMileage",
                        name: "operatingMileage",
                        component: () => import("./views/admin/statisticalAnalysis/operatingMileage/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/operatingMileage/operatingMileageCarTotal",
                        children: [
                            {
                                path: "operatingMileageCarTotal",
                                name: "operatingMileageCarTotal",
                                component: () => import("./views/admin/statisticalAnalysis/operatingMileage/operatingMileageCarTotal.vue")
                            },
                            {
                                path: "operatingMileageCarDetail",
                                name: "operatingMileageCarDetail",
                                component: () => import("./views/admin/statisticalAnalysis/operatingMileage/operatingMileageCarDetail.vue")
                            }
                        ]
                    },
                    {
                        path: "tripMileage",
                        name: "tripMileage",
                        component: () => import("./views/admin/statisticalAnalysis/tripMileage/entry.vue"),
                        redirect: "/admin/statisticalAnalysis/tripMileage/tripMileageCarTotal",
                        children: [
                            {
                                path: "tripMileageCarTotal",
                                name: "tripMileageCarTotal",
                                component: () => import("./views/admin/statisticalAnalysis/tripMileage/tripMileageCarTotal.vue")
                            },
                            {
                                path: "tripMileageCarEveryDay",
                                name: "tripMileageCarEveryDay",
                                component: () => import("./views/admin/statisticalAnalysis/tripMileage/tripMileageCarEveryDay.vue")
                            }
                        ]
                    },
                    {
                        path: "GPStotal",
                        name: "GPStotal",
                        component: () => import("./views/admin/statisticalAnalysis/GPStotal.vue")
                    }
                ]
            },
            {
                path: "detailData",
                name: "detailData",
                component: () => import("./views/admin/detailData/entry.vue"),
                redirect: "/admin/detailData/arriveLeaveDetail",
                children: [
                    {
                        path: "arriveLeaveDetail",
                        name: "arriveLeaveDetail",
                        component: () => import("./views/admin/detailData/arriveLeaveDetail.vue")
                    },
                    {
                        path: "inOutDetail",
                        name: "inOutDetail",
                        component: () => import("./views/admin/detailData/inOutDetail.vue")
                    },
                    {
                        path: "carViolationDetail",
                        name: "carViolationDetail",
                        component: () => import("./views/admin/detailData/carViolationDetail.vue")
                    },
                    {
                        path: "attendanceDetail",
                        name: "attendanceDetail",
                        component: () => import("./views/admin/detailData/attendanceDetail.vue")
                    },
                    {
                        path: "startNoticeDetail",
                        name: "startNoticeDetail",
                        component: () => import("./views/admin/detailData/startNoticeDetail.vue")
                    },
                    {
                        path: "carPlanDetail",
                        name: "carPlanDetail",
                        component: () => import("./views/admin/detailData/carPlanDetail.vue")
                    },
                    {
                        path: "businessRegistrationDetail",
                        name: "businessRegistrationDetail",
                        component: () => import("./views/admin/detailData/businessRegistrationDetail.vue")
                    },
                    {
                        path: "businessRequestsDetail",
                        name: "businessRequestsDetail",
                        component: () => import("./views/admin/detailData/businessRequestsDetail.vue")
                    },
                    {
                        path: "businessChangeDetail",
                        name: "businessChangeDetail",
                        component: () => import("./views/admin/detailData/businessChangeDetail.vue")
                    }
                ]
            },
            {
                path: "basicInformation",
                name: "basicInformation",
                component: () => import("./views/admin/basicInformation/entry.vue"),
                redirect: "/admin/basicInformation/basicData",
                children: [
                    {
                        path: "basicData",
                        name: "basicData",
                        component: () => import("./views/admin/basicInformation/basicData/entry.vue"),
                        redirect: "/admin/basicInformation/basicData/equipmentManufacturers",
                        children: [
                            {
                                path: "equipmentManufacturers",
                                name: "equipmentManufacturers",
                                component: () => import("./views/admin/basicInformation/basicData/equipmentManufacturers.vue")
                            },
                            {
                                path: "deviceType",
                                name: "deviceType",
                                component: () => import("./views/admin/basicInformation/basicData/deviceType.vue")
                            },
                            {
                                path: "siteCategory",
                                name: "siteCategory",
                                component: () => import("./views/admin/basicInformation/basicData/siteCategory.vue")
                            },
                            {
                                path: "depotManagement",
                                name: "basicDataDepotManagement",
                                component: () => import("./views/admin/basicInformation/basicData/depotManagement.vue")
                            },
                            {
                                path: "incomepay",
                                name: "incomepay",
                                component: () => import("./views/admin/basicInformation/basicData/incomepay.vue")
                            }
                        ]
                    },
                    {
                        path: "lineManagement",
                        name: "lineManagement",
                        component: () => import("./views/admin/basicInformation/lineManagement.vue")
                    },
                    {
                        path: "siteManagement",
                        name: "siteManagement",
                        component: () => import("./views/admin/basicInformation/siteManagement.vue")
                    },
                    {
                        path: "driverInformation",
                        name: "driverInformation",
                        component: () => import("./views/admin/basicInformation/driverInformation.vue")
                    },
                    {
                        path: "simCard",
                        name: "simCard",
                        component: () => import("./views/admin/basicInformation/simCard.vue")
                    },
                    {
                        path: "equipmentManagement",
                        name: "equipmentManagement",
                        component: () => import("./views/admin/basicInformation/equipmentManagement.vue")
                    },
                    {
                        path: "vehicleInformation",
                        name: "vehicleInformation",
                        component: () => import("./views/admin/basicInformation/vehicleInformation.vue")
                    },
                    {
                        path: "depotManagement",
                        name: "depotManagement",
                        component: () => import("./views/admin/basicInformation/depotManagement.vue")
                    },
                    {
                        path: "fixedPointManagement",
                        name: "fixedPointManagement",
                        component: () => import("./views/admin/basicInformation/fixedPointManagement.vue")
                    },
                    {
                        path: "divisionsAdjustment",
                        name: "divisionsAdjustment",
                        component: () => import("./views/admin/basicInformation/divisionsAdjustment.vue")
                    },
                    {
                        path: "fixedLineManagement",
                        name: "fixedLineManagement",
                        component: () => import("./views/admin/basicInformation/fixedLineManagement.vue")
                    },
                    {
                        path: "advertiseManagement",
                        name: "advertiseManagement",
                        component: () => import("./views/admin/basicInformation/advertiseManagement.vue")
                    },
                    {
                        path: "promptManagement",
                        name: "promptManagement",
                        component: () => import("./views/admin/basicInformation/promptManagement.vue")
                    },
                    {
                        path: "carDriver",
                        name: "carDriver",
                        component: () => import("./views/admin/basicInformation/carDriver.vue")
                    },
                    {
                        path: "lineSiteQuery",
                        name: "lineSiteQuery",
                        component: () => import("./views/admin/basicInformation/lineSiteQuery.vue")
                    }
                ]
            },
            {
                path: "systemSettings",
                name: "systemSettings",
                component: () => import("./views/admin/systemSettings/entry.vue"),
                redirect: "/admin/systemSettings/organizationManagement",
                children: [
                    {
                        path: "organizationManagement",
                        name: "organizationManagement",
                        component: () => import("./views/admin/systemSettings/organizationManagement.vue")
                    },
                    {
                        path: "roleManagement",
                        name: "roleManagement",
                        component: () => import("./views/admin/systemSettings/roleManagement.vue")
                    },
                    {
                        path: "accountManagement",
                        name: "accountManagement",
                        component: () => import("./views/admin/systemSettings/accountManagement.vue")
                    },
                    {
                        path: "informManagement",
                        name: "informManagement",
                        component: () => import("./views/admin/systemSettings/informManagement.vue")
                    },
                    {
                        path: "broadcast",
                        name: "broadcast",
                        component: () => import("./views/admin/systemSettings/broadcast.vue")
                    },
                    {
                        path: "logonLog",
                        name: "logonLog",
                        component: () => import("./views/admin/systemSettings/logonLog.vue")
                    },
                    {
                        path: "operationLog",
                        name: "operationLog",
                        component: () => import("./views/admin/systemSettings/operationLog.vue")
                    }
                ]
            },
            // {
            //     path: "/alarm/alarmManagement",
            //     name: "alarmManagement",
            //     component: () => import("./views/admin/alarm/alarmManagement.vue")
            // },
            {
                path: "/alarm",
                name: "alarm",
                component: () => import("./views/admin/alarm/entry.vue"),
                children: [
                    {
                        path: "alarmManagement",
                        name: "alarmManagement",
                        component: () => import("./views/admin/alarm/alarmManagement.vue")
                    }
                ]
            }
        ]
    },
    {
        path: "/explore",
        name: "explore",
        component: () => import("./explore/entry.vue"),
        redirect: "/explore/print",
        children: [
            {
                path: "print",
                name: "explorePrint",
                component: () => import("./explore/print.vue")
            }
        ]
    }
];

// const exploreRoute = [{
//     path: '/explore',
//     name: 'explore',
//     component: () => import('./explore/entry.vue'),
//     redirect: 'print',
//     children: [{
//         path: 'print',
//         name: 'explorePrint',
//         component: () => import('./explore/print.vue')
//     }]
// }];

// var mainRoutes = routes;

// if (process.env.NODE_ENV !== 'production') {
//     mainRoutes = routes.concat(exploreRoute);
// }

const router = new VueRouter({
    // mainRoutes
    routes
});

//页面权限控制
function havePage(list, url) {
    let ishave = false;
    list.forEach(item => {
        if (item.url == url) {
            ishave = true;
        }
        if (item.children && item.children.length && !ishave) {
            ishave = havePage(item.children, url);
        }
    });
    return ishave;
}
//
function listTo(obj) {
    let u = "";
    if (obj.children && obj.children.length) {
        u = listTo(obj.children[0]);
    } else {
        u = obj.url;
    }
    return u;
}

const noTokenRouterNamList = ["login", "track","siteTrack"];
router.beforeEach((to, from, next) => {
    console.log(to);
    if (/explore/.test(to.name)) {
        next();
        return;
    }

    let query = Util.getParam();
    if (query.token) {
        Util.setStor("token", query.token);
        let url = location.origin + location.pathname + "#/admin/home1";
        location = url;
        return;
    }

    var token = window.localStorage.getItem("token");
    if (!token && !noTokenRouterNamList.includes(to.name)) {
        next("/login");
    } else {
        let isMenu = store.state.menuList && store.state.menuList.length;
        if ((!store.state.info || !isMenu) && token) {
            store.dispatch("getInfo").then(() => {
                store.dispatch("getMenuTree").then(() => {
                    let list = store.state.menuList;
                    let isHavePage = havePage(list, to.path);
                    if (isHavePage || noTokenRouterNamList.includes(to.name)) {
                        next();
                    } else {
                        let url = "";
                        if (list.length) {
                            url = listTo(list[0]);
                            next(url);
                        } else {
                            url = "/login";
                            if (from.name == "login") {
                                MessageBox({
                                    title: "提示",
                                    message: "你还没有任何权限，请联系管理员添加。",
                                    callback: () => {
                                        Util.removeStor("token");
                                    }
                                });
                            } else {
                                next(url);
                            }
                        }
                    }
                });
                store.dispatch("getHourOverShiftTotal");
            });
        } else {
            let list = store.state.menuList;
            let isHavePage = havePage(list, to.path);
            if (isHavePage || noTokenRouterNamList.includes(to.name)) {
                next();
            } else {
                let url = "";
                if (list.length) {
                    url = listTo(list[0]);
                } else {
                    url = "/login";
                }
                next(url);
            }
        }
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
};

export default router;
