<template>
    <div class="admin_nav" :class="mode">
        <div class="nav_box">
            <el-menu router :default-active="this.$route.path" unique-opened :background-color="backgroundColor" :text-color="TextColor" :active-text-color="activeTextColor" :mode="mode">
                <template v-for="(one) in menu">
                    <el-submenu :index="one.url" v-if="one.children&&one.children.length" v-bind:key="one.id">
                        <template slot="title">
                            <i :class="one.icone" v-if="one.icone"></i>
                            <span style="font-size:18px !important">{{one.text}}</span>
                        </template>
                        <template v-for="(item) in one.children">
                            <el-submenu :index="item.url" v-if="item.children&&item.children.length" v-bind:key="item.id">
                                <template slot="title">
                                    <i :class="item.icone" v-if="item.icone"></i>
                                    <span style="font-size:18px !important">{{item.text}}</span>
                                </template>
                                <template v-for="k in item.children">
                                    <el-menu-item :index="k.url" v-bind:key="k.id">
                                        <i :class="k.icone" v-if="k.icone"></i>
                                        <span slot="title" style="font-size:18px !important">{{k.text}}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                            <el-menu-item :index="item.url" v-bind:key="item.id" v-else style="font-size:18px !important">
                                <i :class="item.icone" v-if="item.icone"></i>
                                <span slot="title" style="font-size:18px !important">{{item.text}}</span>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                    <el-menu-item :index="one.url" v-bind:key="one.id" v-else-if="!one.blank">
                        <i :class="one.icone" v-if="one.icone"></i>
                        <span slot="title" style="font-size:18px !important">{{one.text}}</span>
                    </el-menu-item>
                    <a :href="one.obj.url" v-bind:key="one.id" target="_blank" class="nav_a" v-else>
                        <el-menu-item v-bind:key="one.id">
                            <i :class="one.icone" v-if="one.icone"></i>
                            <span style="font-size:18px !important">{{one.text}}</span>
                        </el-menu-item>
                    </a>
                </template>
            </el-menu>
        </div>
    </div>
</template>
<script>
export default {
    name: 'jy-menu',
    props: {
        backgroundColor: {
            type: String,
            default: '#edf0f5'
        },
        activeTextColor: {
            type: String,
            default: '#05265f'
        },
        TextColor: {
            type: String,
            default: '#fff'
        },
        mode: {
            type: String,
            default: 'vertical' //horizontal 
        },
        menu: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {

        }
    },
    activated() {
        // this.getUserMenu()
    },
    watch: {},
    methods: {}
}

</script>
<style lang="scss" scoped="admin_nav">
@import '../config/variable.scss';

.admin_nav {
    &.vertical {
        height: 100%;
        background-color: $menu_bg;
        overflow-y: scroll;
        margin-right: -20px;

        .nav_box {
            width: 100%;

            .el-menu {
                border: none;
                .is-alive {
                    // background: rgb(67, 74, 80) !important;
                }
            }
        }
    }
    .nav_a{
        text-decoration: none;
    }
}

</style>
<style lang="scss">
@import '../config/variable.scss';
.admin_nav {
    &.vertical {

        .el-menu-item,
        .el-submenu__title {
            &.is-active {
                * {
                    color: $menu_active;
                }
            }

            * {
                color: #5b5b5b;
            }
        }

        .el-submenu.is-active:not(.is-opened) .el-submenu__title,
        .el-menu-item.is-active {
            // background: rgb(67, 74, 80) !important;
            position: relative;
            i {
                &.el-submenu__icon-arrow {
                    color: $menu_active;
                    font-weight: bold;
                }
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 4px;
                background: $menu_active;
            }
        }
    }
    &.horizontal {
        .el-menu-item,
        .el-submenu__title {
            &.is-active {
                * {
                    color: #fff;
                    background: $menu_active;
                }
            }

            * {
                color: #fff;
                background: $menu_active;
            }
        }

        .el-submenu.is-active:not(.is-opened) .el-submenu__title,
        .el-menu-item.is-active {
            background: #041e4c !important;
            position: relative;
            i {
                &.el-submenu__icon-arrow {
                    color: $menu_active;
                    font-weight: bold;
                }
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 4px;
                background: $menu_active;
            }
        }
    }
}

</style>
