import Http from "@/common/js/http.js";

const x_PI = (3.14159265358979324 * 3000.0) / 180.0;
const PI = 3.1415926535897932384626;
const a = 6378245.0;
const ee = 0.00669342162296594323;

const util = {
    deepCopy(obj) {
        let objClone = Array.isArray(obj) ? [] : {};
        if (obj && typeof obj === "object") {
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] && typeof obj[key] === "object") {
                        objClone[key] = this.deepCopy(obj[key]);
                    } else {
                        objClone[key] = obj[key];
                    }
                }
            }
        }
        return objClone;
    },
    //随机字符串
    randomString(len) {
        len = len || 32;
        var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = "";
        for (let i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    //坐标转换
    transformlat(lng, lat) {
        var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
        ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0;
        ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0;
        ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0;
        return ret;
    },
    transformlng(lng, lat) {
        var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
        ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0;
        ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0;
        ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0;
        return ret;
    },
    bd09togcj02(bd_lon, bd_lat) {
        var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
        var x = bd_lon - 0.0065;
        var y = bd_lat - 0.006;
        var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
        var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
        var gg_lng = z * Math.cos(theta);
        var gg_lat = z * Math.sin(theta);
        return [gg_lng, gg_lat];
    },
    gcj02tobd09(lng, lat) {
        var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
        var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
        var bd_lng = z * Math.cos(theta) + 0.0065;
        var bd_lat = z * Math.sin(theta) + 0.006;
        return [bd_lng, bd_lat];
    },
    wgs84togcj02(lng, lat) {
        var dlat = this.transformlat(lng - 105.0, lat - 35.0);
        var dlng = this.transformlng(lng - 105.0, lat - 35.0);
        var radlat = (lat / 180.0) * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
        dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
        const mglat = lat + dlat;
        const mglng = lng + dlng;
        return [mglng, mglat];
    },
    gcj02towgs84(lng, lat) {
        var dlat = this.transformlat(lng - 105.0, lat - 35.0);
        var dlng = this.transformlng(lng - 105.0, lat - 35.0);
        var radlat = (lat / 180.0) * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
        dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
        const mglat = lat + dlat;
        const mglng = lng + dlng;
        return [lng * 2 - mglng, lat * 2 - mglat];
    },
    bd09towgs84(lng, lat) {
        // 百度坐标系先转为火星坐标系
        const gcj02 = this.bd09togcj02(lng, lat);
        // 火星坐标系转wgs84坐标系
        const result = this.gcj02towgs84(gcj02[0], gcj02[1]);
        return result;
    },
    wgs84tobd09(lng, lat) {
        // wgs84先转为火星坐标系
        const gcj02 = this.wgs84togcj02(lng, lat);
        // 火星坐标系转百度坐标系
        const result = this.gcj02tobd09(gcj02[0], gcj02[1]);
        return result;
    },

    //获取链接 search 所带的参数
    getParam: function() {
        var q = location.search.substr(1);
        var qs = q.split("&");
        var arg = {};
        if (q && qs) {
            for (var i = 0; i < qs.length; i++) {
                arg[qs[i].substring(0, qs[i].indexOf("="))] = decodeURI(qs[i].substring(qs[i].indexOf("=") + 1)); //decodeURI 进行解码
            }
        }
        return arg;
    },
    //设置 title
    setTitle: function(title) {
        document.getElementsByTagName("title")[0].innerText = title;
    },
    //更改地址栏的请求地址面不刷新页面
    reHref: function(param, type, state) {
        var url = param;
        state = state ? state : {};
        if (!type || type === "hash" || type === 0) {
            var href = location.href.replace(/#.*/, "");
            if (/^\//.test(url)) {
                url = "#" + url;
            } else if (/^\w*/.test(url)) {
                url = "#/" + url;
            }
            url = href + url;
        }
        try {
            window.history.replaceState(state, "", url);
        } catch (e) {
            console.error(e);
        }
    },
    //添加地址栏的请求地址面不刷新页面
    addHref: function(param, type, state) {
        var url = param;
        state = state ? state : {};
        if (!type || type === "hash" || type === 0) {
            var href = location.href.replace(/#.*/, "");
            url = href + "#/" + param;
        }
        try {
            window.history.pushState(state, "", url);
        } catch (e) {
            console.error(e);
        }
    },
    //获取 localStorage 的缓存内容
    getStor: function(name) {
        var stor = localStorage.getItem(name);
        if (stor) {
            stor = JSON.parse(stor);
        }
        return stor;
    },
    //设置 localStorage 的缓存内容
    setStor: function(name, param) {
        var stor = JSON.stringify(param);
        localStorage.setItem(name, stor);
    },
    //删除 localStorage 的缓存内容
    removeStor: function(name) {
        localStorage.removeItem(name);
    },
    //判断浏览器的类型
    isBrowser: function(type) {
        var ua = navigator.userAgent.toLowerCase();
        var value;
        switch (type) {
            case "ios":
                value = /iphone|ipad|ipod/.test(ua);
                break;
            case "android":
                value = /android/.test(ua);
                break;
            case "wx":
                value = /micromessenger/.test(ua);
                break;
        }
        return value;
    },
    //导出
    funDownload(content, filename, type) {
        let t = type ? type : "excel";
        let blobType = "";
        // 创建隐藏的可下载链接
        var eleLink = document.createElement("a");
        if (t == "excel") {
            //Excel表格
            eleLink.download = filename + ".xls";
            blobType = "application/vnd.ms-excel";
        }
        eleLink.style.display = "none";
        // 字符内容转变成blob地址
        var blob = new Blob([content], {
            type: blobType
        });
        eleLink.href = URL.createObjectURL(blob);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
    },
    //按钮权限
    getButtons(btnMenuId) {
        return new Promise(resolve => {
            let url = "/sys/role/pageQueryMenu";
            let option = {
                sysMenu: {
                    pMenuId: btnMenuId,
                    tType: "2"
                },
                menuId: btnMenuId
            };
            Http.post(url, option).then(res => {
                console.log(res)
                resolve(res);
            });
        });
    },
    //年月日时分秒格式化
    formatTime(time) {
        let newTime = time;
        if (time && String(time).length == 14) {
            newTime = time.slice(0, 4) + "-" + time.slice(4, 6) + "-" + time.slice(6, 8) + " " + time.slice(8, 10) + ":" + time.slice(10, 12) + ":" + time.slice(12, 14);
        } else if (time && String(time).length == 8) {
            newTime = time.slice(0, 4) + "-" + time.slice(4, 6) + "-" + time.slice(6, 8);
        }
        return newTime;
    },
    //获取日期中间的日期数组
    getBetweenDateList(beginDate, endDate) {
        let dateList = [];
        let oneDate = 1000 * 60 * 60 * 24;
        let N = ["日", "一", "二", "三", "四", "五", "六"];
        if (beginDate && endDate) {
            let beginDateTime = new Date(beginDate).getTime();
            let endDateTime = new Date(endDate).getTime();
            let days = (endDateTime - beginDateTime) / oneDate + 1;
            for (let i = 0; i < days; i++) {
                let dateTime = beginDateTime + i * oneDate;
                let item = {
                    date: new Date(dateTime).Format("yyyy-MM-dd"), //日期
                    wed: N[new Date(dateTime).getDay()], //周数
                    day: new Date(dateTime).Format("yyyy-MM-dd").split("-")[2] * 1, //号数
                    isCheck: false //选择
                };
                dateList.push(item);
            }
        }
        return dateList;
    },
    isChinese(temp) {
        if (escape(temp).indexOf("%u") < 0) {
            return false;
        }
        return true;
    },
    fixedMacTime(dateStr) {
        const timeStr = dateStr;
        if (timeStr && timeStr.length === 12) {
            const year = `20${timeStr.substr(0, 2)}`;
            const month = timeStr.substr(2, 2);
            const day = timeStr.substr(4, 2);
            const hour = timeStr.substr(6, 2);
            const minute = timeStr.substr(8, 2);
            const second = timeStr.substr(10, 2);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        } else if (timeStr && timeStr.length === 6) {
            const year = `20${timeStr.substr(0, 2)}`;
            const month = timeStr.substr(2, 2);
            const day = timeStr.substr(4, 2);
            return `${year}-${month}-${day}`;
        } else {
            return timeStr;
        }
    },
    calculateOnlineByTime(time) {
        // 根据传入时间与当前时间判断设备是否在线
        let status = false;
        var current = new Date().getTime();
        const reg = /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
        if (reg.test(time)) {
            var ms = new Date(time.replace(/-/g, "/")).getTime();
            if (current - ms < 1000 * 60 * 6) {
                status = true;
            }
        }
        return status;
    },
    //获取当前yyyy-mm-dd格式的日期
    getNowDate() {
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    }
};

export default util;
