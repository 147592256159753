export default {
	name: 'visibility',
	bind: function(el, binding) {
		if (binding.value) {
			show(el)
		} else {
			hide(el)
		}
	},
	update: function(el, binding) {
		if (binding.value) {
			show(el)
		} else {
			hide(el)
		}
	}
}

function show(el){
	el.style.visibility = 'visible'
}
function hide(el){
	el.style.visibility = 'hidden'
}
