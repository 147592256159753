import empty from './empty'
import visibility from './visibility'
import loadmore from './loadmore'

let directives = [
	empty,
	visibility,
	loadmore
]

export default directives