import login from './login'
import container from './container'
import top from './top'
import menu from './menu'
import homesum from './homesum'
//频繁使用
import operate from './table/operate'
import pagination from './table/pagination'
import table from './table/table'
import tableColumn from './table/table.column'
import query from './query/query'
import queryItem from './query/query.item'
import dialog from './dialog/index'
//screen
import screenMainBox from './screen/mainbox'
import screenContentBox from './screen/contentbox'

//业务
import importCom from './import/index'
import treeTransfer from './tree-transfer/index'

const components = [
	login,
	container,
	top,
	menu,
	homesum,
	//频繁使用
	operate,
	pagination,
	table,
	tableColumn,
	query,
	queryItem,
	dialog,
	//screen
	screenMainBox,
	screenContentBox,
	//业务
	importCom,
	treeTransfer,
];

export default components